import React from "react";
import {
	BlueSquares1,
	BlueSquares2,
	PinkSquares0,
	PinkSquares1,
	PinkCircles0,
	PinkCircles1,
} from "@assets/Background";
import { PageLayout, TrustedBy } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { GetADemo } from "@components/GetADemo/GetADemo";
import { StaticImage } from "gatsby-plugin-image";
import { pinkText } from "@components/Typography/Typography";
import ImageAndBulletPoints from "@components/ImageAndBulletPoints/ImageAndBulletPoints";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import { useWindowSize } from "@helpers/Hooks";
import LearnMoreSection from "@components/LearnMoreSection/LearnMoreSection";
import { ArrowLinkButton } from "@components/Button_new/Button";
import * as styles from "../../CommonResourcesOverview.module.scss";

export default function ResearchAndDevelopment() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "research-and-development",
			}}
		>
			<Demo />
			<OnlySolution />
			<NotebookCollaboration />
			<FullyManaged />
			<TrustedBy />
			<LearnMoreSection textColor="text-dark" background="pinkFade" />
		</PageLayout>
	);
}

const Demo = () => (
	<BackgroundFadeContainer type="blueFade" skewBottom withHeaderPadding>
		<BackgroundShapes
			leftShape={{
				shape: <BlueSquares1 />,
				xOffset: -140,
				yOffset: 120,
			}}
			rightShape={{ shape: <BlueSquares2 />, xOffset: 140 }}
		>
			<GetADemo
				titleText="Genemod is where the future works"
				subtitleText="The modern lab automation platform to accelerate scientific research from discovery to manufacturing operations."
				linkColor="ui_01"
				titleWidth={550}
				subtitleWidth={550}
				imageNode={
					<StaticImage
						loading="eager"
						src={
							"../../../assets/products/consumable-room-layout.png"
						}
						alt="consumable feature room layout example"
					/>
				}
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const OnlySolution = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<BackgroundShapes
			leftShape={{
				shape: <PinkSquares0 />,
				xOffset: -140,
				yOffset: 10,
			}}
			rightShape={{ shape: <PinkSquares1 />, xOffset: 170, yOffset: 180 }}
		>
			<SectionTitle
				title={pinkText`The ${"only solution"} that doesn’t look like another spreadsheet`}
				subtitle="Custom design a virtual freezer that matches its real-life counterpart, shelf for shelf and item for item."
				textColor="text-dark"
				titleWidth={850}
				subtitleWidth={596}
				marginBottom={12}
				marginBottomTablet={12}
				marginBottomMobile={12}
			/>
			<StaticImage
				loading="eager"
				src="../../../assets/products/Freezer-rack-large.png"
				alt="freezer rack example page"
				className={styles.fullWidthImage}
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const NotebookCollaboration = () => (
	<BackgroundFadeContainer type="blueFade" skewTop skewBottom>
		<SectionTitle
			title={pinkText`Genemod Notebook takes R&D collaboration to a ${"whole other level"}`}
			subtitle="Create flexible and editable protocol templates, add attachments to experiments, and build Excel-like tables within entries."
			textColor="ui-01"
			marginBottom={30}
			marginBottomTablet={60}
			titleWidth={962}
			subtitleWidth={802}
		/>
		<ImageAndBulletPoints
			width={966}
			bullets={[
				{
					title: "Unlock better insights",
					text: "In the last few years, every team has undergone a digital transformation and is accumulating more and more data. Researchers need access to the latest experimental, assay / screening data, and inventory data from a variety of sources.",
				},
				{
					title: "Boost productivity",
					text: "Providing scientists access to all data from a single interface allows for a seamless workflow and removes the need to switch between applications..",
				},
			]}
			image={
				<StaticImage
					loading="eager"
					src="../../../assets/products/ROI-graph.png"
					alt="3rd party, heap estimate of savings by using genemod"
					width={441}
					height={477}
				/>
			}
			bulletStyles={{
				titleVariant: "HEADER4",
				titleColor: "ui-01",
				textVariant: "SUBHEADERDESC",
				textColor: "ui-01",
				containerWidth: 476,
			}}
		/>
	</BackgroundFadeContainer>
);

const FullyManaged = () => {
	const { isMobile } = useWindowSize();

	return (
		<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
			<BackgroundShapes
				leftShape={{
					shape: <PinkCircles0 />,
					xOffset: -140,
					yOffset: 120,
				}}
				rightShape={{ shape: <PinkCircles1 />, xOffset: 140 }}
			>
				<SectionTitle
					title={pinkText`A ${"fully managed"}, R&D data platform `}
					subtitle="Our Enterprise plans help companies ensure data integrity and improve collaboration institution-wide. Each subscription is acustom-built, expert-driven foundation of your lab automation program."
					textColor="text-dark"
					titleWidth={760}
					subtitleWidth={802}
				/>
				<div className={styles.learnMoreBtn}>
					<ArrowLinkButton color="marine" link="DEMO">
						Learn more
					</ArrowLinkButton>
				</div>
				{isMobile && (
					<StaticImage
						loading="eager"
						className={styles.fullWidthImage}
						src="../../../assets/products/onboard-workflow-diagram-vertical.png"
						alt="diagram showing the onboarding to genemod process"
					/>
				)}
				{!isMobile && (
					<StaticImage
						loading="eager"
						className={styles.fullWidthImage}
						src="../../../assets/products/onboard-workflow-diagram.png"
						alt="diagram showing the onboarding to genemod process"
					/>
				)}
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
};
